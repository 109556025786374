import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack } from "@mui/material";
import { useState, useEffect } from "react";

export const DialogID = ({ socket, requestidOpen, setRequestidOpen }) => {
  const [facts, setFacts] = useState([]);
  const [factArray, setFactArray] = useState([]);

  useEffect(() => {
    // Fetch factArray when dialog opens
    if (requestidOpen && socket) {
      socket.send(JSON.stringify({ "type": "credential-list" }));
    }

    // Handle incoming messages
    const handleMessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === "credential-list") {
        setFactArray(message.payload); // Set received fact array
      }
    };

    if (socket) {
      socket.addEventListener("message", handleMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener("message", handleMessage);
      }
    };
  }, [requestidOpen, socket]);

  const handleFactsChange = (e) => {
    const {
      target: { value },
    } = e;
  
    if (value) {
      setFacts(typeof value === "string" ? value.split(",") : value);
    } else {
      setFacts([]); // set an empty array if value is undefined
    }
  };
  
  const handleRequestidClose = () => {
    setRequestidOpen(false);
    setFacts([]);
  };

  const handleRequestidSubmit = () => {
    if (!socket) return;
    socket.send(
      JSON.stringify({ "type": "credential-req", "payload": { "credentials": facts } })
    );
    setRequestidOpen(false);
    setFacts([]);
  };

  return (
    <Dialog
      fullWidth
      open={requestidOpen}
      onClose={handleRequestidClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Request Credentials"}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={{ height: 0, visibility: "hidden" }} />
          <FormControl size="small" sx={{ mt: 1 }}>
            <InputLabel id="select-facts-label">Select Credentials</InputLabel>
            <Select
              labelId="select-facts-label"
              multiple
              value={facts}
              onChange={handleFactsChange}
              input={<OutlinedInput label="Select Credentials" />}
            >
              {factArray.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRequestidClose}>Cancel</Button>
        <Button disabled={facts.length === 0} onClick={handleRequestidSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
